import React, {useEffect} from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import BreadCrumbs from "../components/bread-crumbs";

const breadCrumbsList  = [

    {
        title: "Home",
        link: "/",
    }

]

const NotFoundPage = () => {

    useEffect(() => {
        window.analytics.page("404");
    }, []);

    return (
        <Layout>
            <SEO title="404: Not found"/>
            <BreadCrumbs currentPage={"404"} breadCrumbsList={breadCrumbsList}/>
            <div className="not-found-container">
                <div className="inner-container">
                    <img className="nf-img" src={require('../assets/images/404.jpg')} alt=""/>
                    <h2 className="nf-head">Whoops, something has stolen this page.</h2>
                    <p className="nf-parah">Click the back button to get back on track.</p>
                </div>
            </div>
        </Layout>
    );
};

export default NotFoundPage
